function Tabs() {

    console.log("tabs");

    $( ".tab-link" ).each(function(i) {

        console.log("tab clicked " + i);

        $(this).click(function () {

            var tab = "#tab-" + i;
            var content = "#tab-content-" + i;

            $(".tab-link").removeClass("active");
            $(tab).addClass("active");

            console.log(content);

            function showBlock(){

                TweenMax.set(".tab-content", {autoAlpha:0,display:'none'});
                TweenMax.set(content, {autoAlpha:1,display:'block'});
                TweenMax.set('#tab-content-holder', {height:"auto"});
                TweenMax.from('#tab-content-holder', 0.8, {height:0, ease:Sine.easeInOut});

            }

            TweenMax.to('#tab-content-holder', 0.5, {height:0, ease:Sine.easeInOut, onComplete:showBlock});

        });

    });

    $( ".dropdown-link" ).each(function(i) {

        console.log("tab clicked " + i);

        $(this).click(function () {

            var tab = "#tab-" + i;
            var content = "#tab-content-" + i;

            $(".tab-link").removeClass("active");
            $(tab).addClass("active");

            console.log(content);

            function showBlock(){

                TweenMax.set(".tab-content", {autoAlpha:0,display:'none'});
                TweenMax.set(content, {autoAlpha:1,display:'block'});
                TweenMax.set('#tab-content-holder', {height:"auto"});
                TweenMax.from('#tab-content-holder', 0.8, {height:0, ease:Sine.easeInOut});

            }

            TweenMax.to('#tab-content-holder', 0.5, {height:0, ease:Sine.easeInOut, onComplete:showBlock});

        });

    });

    function setInitialTab(i){

        var tab = "#tab-" + i;
        var content = "#tab-content-" + i;

        $(tab).addClass("active");

        TweenMax.set(".tab-content", {autoAlpha:0,display:'none'});
        TweenMax.set(content, {autoAlpha:1,display:'block'});
        TweenMax.set('#tab-content-holder', {height:"auto"});

    }

    setInitialTab(0);

    //   TweenMax.set('.form', {height:0})
    //  TweenMax.set('.form', {height:"auto"})
    // TweenMax.from('.form', 0.8, {height:0, ease:Sine.easeInOut})

    // set tab to url

    // var hash = window.location.hash.substring(1);

    // tab clicked

    // prevent default

    // change tab

    // set url

   // var hash = window.location.hash.substring(1);



}