/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var $ = jQuery;

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {

        // JavaScript to be fired on all pages

        $('#open-menu').on('click', function () {
          $('.menu-main-menu-container').fadeIn();
        });
        $('#close-menu').on('click', function () {
          $('.menu-main-menu-container').fadeOut();
        });

        $('iframe:not(.stockdio_quotes)').each(function () {
          var url = $(this).attr('src');
          if (url.indexOf('youtube') || url.indexOf('vimeo')) {
            $(this).wrap('<div class="sizer"><div class="videoWrapper"></div></div>');
          }
        });

        $('.kv-thumbnail').each(function () {
          var url = $(this).attr('src');
          if (url.indexOf('qumucloud')) {
            $(this).parent('kv-thumbnail-holder').wrap('<div class="sizer"><div class="videoWrapper"></div></div>');
          }
        });

        $('.atf-tweet-wrapper').wrap('<div class="col-sm-4"></div>');

        $('.grid').imagesLoaded(function () {
          $('.grid').masonry({
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            percentPosition: true,
            gutter: 15
          });
        });

        var $grid = $('.nl-grid').imagesLoaded(function () {
          $('.nl-grid').isotope({
            itemSelector: '.nl-item',
            columnWidth: '.grid-sizer',
            masonry: {
              gutter: 15
            }
          });
        });

        $('.filters-list').on('click', 'a', function () {
          $('a').removeClass('is-checked');
          $(this).addClass('is-checked');
          var filterValue = $(this).attr('data-filter');

          $grid.isotope({filter: filterValue});

        });

        $('.newsletter-taxonomies').on('click', 'a', function (e) {
          e.preventDefault();
          $('a').removeClass('is-current-filter');
          $(this).addClass('is-current-filter');

          $('.filters-button-group button').removeClass('is-checked');

          $('button[data-filter="' + $(this).attr('data-filter') + '"]').addClass('is-checked');

          var filterValue = $(this).attr('data-filter');

          $grid.isotope({filter: filterValue});

        });

        AOS.init();

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {

        var heroSwiper = new HeroSwiper();
        var logoswiper = new LogoSwiper();
        var tabs = new Tabs();

        $('.fancybox-media').fancybox({
          fitToView: false,
          maxWidth: "90%",
          iframe: {
            preload: false
          },
          openEffect: 'none',
          closeEffect: 'none',
          helpers: {
            media: {}
          }
        });

        $('[data-toggle="popover"]').popover();

        $('.tab-link').hover(
          function () {
            $(this).find('img').attr('src', $(this).find('img').data('doodle'));
          }, function () {
            $(this).find('img').attr('src', $(this).find('img').data('professional'));

          }
        );

        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_template_casestudies': {
      init: function () {
        var tabs = new Tabs();
      }
    },
    'page_template_template_contact': {
      init: function () {

        $('.google-maps').click(function () {
          $('.google-maps iframe').css("pointer-events", "auto");
        });

        $('.google-maps').mouseleave(function () {
          $('.google-maps iframe').css("pointer-events", "none");
        });

      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
