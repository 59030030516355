function VideoPopup(){

   $(".fancybox").fancybox({
        helpers : {
            overlay : {
                css : {
                    'background' : 'rgba(0, 0, 0, 0.7)'
                }
            }
        },

        'onComplete' : function() {$("#fancybox-wrap").unbind('mousewheel.fb');} ,

        'hideOnOverlayClick' : true,
        'hideOnContentClick' : true,
        'transitionIn'                :        'fade',
        'transitionOut'                :        'elastic',
        'scrolling' : 'no',
        'titleShow'        : false,
        'showNavArrows' : false,
        'padding' : 0

    });
}