function HeroSwiper() {

    var swiper = new Swiper('#hero-swiper', {

        autoplayDisableOnInteraction: false,
        loop: true,
        speed: 1000,
        // autoplay: 6000,
        autoplay: false,
        pagination: '.swiper-pagination',
        paginationClickable: true,
        calculateHeight: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',

    });

    swiper.on('onSlideChangeStart', function () {

        $('.videoWrapper').each(function () {
            videoURL = $(this).find('iframe').attr('src');

            $(this).find('iframe').attr('src', '');
            $(this).find('iframe').attr('src', videoURL);

        });
    });


    $('.swiper-container').hover(
        function () {
            swiper.stopAutoplay();
        }, function () {
            swiper.startAutoplay();
        }
    );

}

function LogoSwiper() {

    var logoswiper = new Swiper('#logo-swiper', {
        paginationClickable: true,
        slidesPerView: 4,
        spaceBetween: 30,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        autoplay: 4000,
        loop: true,
        autoplayDisableOnInteraction: true,
        breakpoints: {
            992: {
                slidesPerView: 3,
                spaceBetween: 50
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 50
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 50
            },
            500: {
                slidesPerView: 1,
                spaceBetween: 50
            }
        }
    });

    logoswiper.on('onSlideChangeStart', function () {
        $('.swiper-slide a').popover('hide');
    });

    $('.swiper-container').hover(
        function () {
            logoswiper.stopAutoplay();
        }, function () {
            logoswiper.startAutoplay();
        }
    );

}
